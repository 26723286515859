<template>
  <div style="width: 100%">
    <div class="attachment_box" v-viewer="options">
      <div class="attachment_container" v-for="(file,index) in fileList" :key="index">
        <template v-if="file.loading">
          <img src="@/assets/image/loading.gif" alt="">
        </template>
        <template v-else>
          <img
            v-if="file.fileType === 'image'"
            :src="file.full_url"
            class="image"
            :data-source="file.full_url"
            :alt="file.original_name"
          >
          <img @click="downloadFile(file.full_url, file.original_name)" v-else :src="file.fileType | fileTypeIcon">
          <a-icon theme="twoTone" two-tone-color="#ff4d4f" type="close-circle" @click="removeFile(index)"/>
        </template>
      </div>
      <a-upload
        name="file"
        :action="serverUrl"
        :show-upload-list="false"
        :multiple="true"
        :accept="'image/*'"
        :data="initData"
        :before-upload="beforeInUpload"
        @change="handleChange"
      >
        <img style="border: 1px dotted #c9c9c9;" src="@/assets/image/add.png">
      </a-upload>
    </div>
  </div>
</template>

<script>
import { getSign } from '@/utils/utils'
import fileTypeIcon from '@/assets/fileTypeIcon'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import Vue from 'vue'
Vue.use(Viewer, {
  debug: false,
  defaultOptions: {
    zIndex: 9999
  }
})

export default {
  name: 'AttachmentList',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: [Array, String]
    }
  },
  data () {
    return {
      fileList: [],
      options: {
        toolbar: true,
        url: 'data-source'
      },
      serverUrl: process.env.VUE_APP_API_BASE_URL + '/upload/file' // 这里写你要上传的图片服务器地址
    }
  },
  filters: {
    fileTypeIcon (fileType) {
      return fileTypeIcon[fileType]
    }
  },
  methods: {
    initData () {
      const p = getSign()
      return p
    },
     beforeInUpload (file) {
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isLt5M) {
        this.$message.config({
          top: `60px`
        })
        this.$message.error('文件大小不能大于5M')
        return false
      } else {
        this.fileList.push({ uid: file.uid, loading: true })
        return isLt5M
      }
    },
    matchFileType (fileName) {
      const pos = fileName.lastIndexOf('.')
      const lastName = fileName.substring(pos, fileName.length)
      let fileType = ''
      switch (lastName.toLowerCase()) {
        case '.jpg':
        case '.png':
        case '.bmp':
        case '.jpeg': fileType = 'image'; break
        case '.xls':
        case '.xlsx': fileType = 'xls'; break
        case '.doc':
        case '.docx': fileType = 'doc'; break
        case '.ppt':
        case '.pptx': fileType = 'ppt'; break
        case '.rar':
        case '.zip':
        case '.7z': fileType = 'zip'; break
        case '.txt': fileType = 'txt'; break
        case '.mp4': fileType = 'video'; break
        default : fileType = 'file'
      }
      return fileType
    },
    downloadFile (url, fileName) {
      const x = new XMLHttpRequest()
      x.open('GET', url, true)
      x.responseType = 'blob'
      x.onload = function () {
        const url = window.URL.createObjectURL(x.response)
        const a = document.createElement('a')
        a.href = url
        a.download = fileName
        a.click()
      }
      x.send()
    },
    handleChange (info) {
      if (info.file.status !== 'uploading') {
        this.beforeUpload()
      }
      if (info.file.status === 'done') {
        this.uploadSuccess(info.file)
      } else if (info.file.status === 'error') {
        this.uploadError()
      }
    },
    // 图片上传前
    beforeUpload () {
      // 显示loading动画
      this.quillUpdateImg = true
    },
    // 图片上传成功
    uploadSuccess (file) {
      const { response, uid } = file
      // res为图片服务器返回的数据
      // 如果上传成功
      if (response.success && response.data && response.data.full_url) {
        // res.data.full_url为服务器返回的图片地址
        let url = response.data.full_url
        const fileType = this.matchFileType(url)
        let model = this.fileList.find(item => item.uid === uid)
        const index = this.fileList.findIndex(item => item.uid === uid)
        if (model) {
          model = { ...model, loading: false, id: null, full_url: url, url: response.data.url, original_name: response.data.original_name, fileType: fileType }
          this.fileList[index] = model
        }
      } else {
        this.$message.config({
          top: `60px`
        })
        this.$message.error('图片上传失败')
      }
      // loading动画消失
      this.quillUpdateImg = false
      this.$emit('input', this.fileList)
      this.$emit('change', this.fileList)
    },
    // 图片上传失败
    uploadError () {
      // loading动画消失
      this.quillUpdateImg = false
      this.$message.config({
        top: `60px`
      })
      this.$message.error('图片上传失败')
    },
    removeFile (index) {
      this.fileList.splice(index, 1)
      this.$emit('input', this.fileList)
      this.$emit('change', this.fileList)
    },
    initValue () {
      const attachments = []
      if (this.value) {
        let data = JSON.parse(JSON.stringify(this.value))
        if (typeof data === 'string') {
          data = JSON.parse(data)
        }
        data && data.map(x => {
          if (!x.loading) {
            x.fileType = this.matchFileType(x.url)
          }
          attachments.push(x)
          // let url = x.full_url
          // attachments.push({ id: x.id, url: x.url, uid: x.uid, loading: false, full_url: url, original_name: x.original_name, fileType: this.matchFileType(x.url) })
        })
      }
      this.fileList = attachments
    }
  },
  mounted () {
    this.initValue()
  },
  watch: {
    value () {
      this.initValue()
    }
  }
}
</script>

<style lang="less" scoped>
.attachment_box{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .attachment_container, span{
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    padding-bottom: 32%;
    margin-bottom: 10px;
    height: 0;
    position: relative;
    img{
      width: 107%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
    i{
      color: red;
      font-size: 20px;
      position: absolute;
      right: -10px;
      top: -6px;
    }
  }
  .attachment_container:nth-child(3n+2) {
    margin: 0 5%;
  }
  .attachment_container:nth-child(3n+1) + span {
    margin: 0 5%;
  }
}
.upload_btn{
  display: inline-flex;
}
</style>
