<template>
  <div>
    <a-form-model-item
      v-for="(item,index) in fieldList"
      :key="index"
      :label="item.label"
      :prop="getProp(item)"
      :rules="getRules(item)">
      <template v-if="item.type === FIELD_TYPE.TEXT_IPT.v">
        <a-input v-model="form[item.name]"/>
      </template>
      <template v-if="item.type === FIELD_TYPE.MULTI_TEXT_IPT.v">
        <a-textarea
          :auto-size="{ minRows: 3, maxRows: 6 }"
          v-model="form[item.name]"/>
      </template>
      <template v-if="item.type === FIELD_TYPE.NUM_IPT.v">
        <a-input-number
          v-model="form[item.name]"/>
      </template>
      <template v-if="item.type === FIELD_TYPE.SELECTOR.v">
        <a-select
          allowClear
          showSearch
          v-model="form[item.name]">
          <template v-for="(selectData) in item.field_options">
            <a-select-option
              :class="{hidden_disabled: selectData.del === BOOL.YES.v}"
              :value="parseInt(selectData.the_key)"
              :key="parseInt(selectData.the_key)">{{ selectData.the_value }}
            </a-select-option>
          </template>
        </a-select>
      </template>
      <template v-if="item.type === FIELD_TYPE.MULTI_SELECTOR.v">
        <a-select
            mode="multiple"
            allowClear
            showSearch
            @change="(values) => { multiSelectorChange(values, item.name) }"
            v-model="multiValue[item.name]">
          <template v-for="(selectData) in item.field_options">
            <a-select-option
                :class="{hidden_disabled: selectData.del}"
                :value="parseInt(selectData.the_key)"
                :key="parseInt(selectData.the_key)">{{ selectData.the_value }}
            </a-select-option>
          </template>
        </a-select>
      </template>
      <template v-if="item.type === FIELD_TYPE.DATE.v">
        <datetime
          format="yyyy-MM-dd"
          @close="closeDatePicker(item.name)"
          v-model="form[item.name + '_picker']"
          :phrases="{ok: '确定', cancel: '关闭'}"></datetime>
      </template>
      <template v-if="item.type === FIELD_TYPE.DATETIME.v">
        <datetime
          type="datetime"
          @close="closeDateTimePicker(item.name)"
          format="yyyy-MM-dd HH:mm:ss"
          :phrases="{ok: '确定', cancel: '关闭'}"
          v-model="form[item.name + '_picker']"></datetime>
      </template>
      <template v-if="item.type === FIELD_TYPE.FILE.v">
        <attachment-upload v-model="form[item.name]"/>
      </template>
      <template v-if="item.type === FIELD_TYPE.MOBILE.v">
        <a-input v-model="form[item.name]"/>
      </template>
      <template v-if="item.type === FIELD_TYPE.EMAIL.v">
        <a-input v-model="form[item.name]"/>
      </template>
    </a-form-model-item>
  </div>
</template>
<script>
import 'vue-datetime/dist/vue-datetime.css'
import { Datetime } from 'vue-datetime';
import AttachmentUpload from '@/components/Attachment/upload'
export default {
  components: { AttachmentUpload, Datetime },
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    fieldList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      multiValue: {},
      FIELD_TYPE: {
        TEXT_IPT: {
          v: 'text_ipt',
          name: '单行文本'
        },
        MULTI_TEXT_IPT: {
          v: 'multi_text_ipt',
          name: '多行文本'
        },
        NUM_IPT: {
          v: 'num_ipt',
          name: '数字输入框'
        },
        SELECTOR: {
          v: 'selector',
          name: '下拉框'
        },
        MULTI_SELECTOR: {
          v: 'multi_selector',
          name: '多选下拉框'
        },
        DATE: {
          v: 'date',
          name: '日期'
        },
        DATETIME: {
          v: 'datetime',
          name: '时间'
        },
        FILE: {
          v: 'file',
          name: '附件'
        },
        MOBILE: {
          v: 'mobile',
          name: '电话'
        },
        EMAIL: {
          v: 'email',
          name: '邮箱'
        }
      },
      BOOL: {
        YES: {
          v: 1,
          name: '是',
          enableName: '禁用',
          enableColor: 'red',
          buttonType: 'primary',
          buttonName: '启用',
          readName: '已读'
        },
        NO: {
          v: 2,
          name: '否',
          enableName: '启用',
          enableColor: 'blue',
          buttonType: 'danger',
          buttonName: '禁用',
          readName: '未读'
        }
      }
    }
  },
  created: function () {},
  methods: {
    multiSelectorChange (values, name) {
      // this.form[name] = values.join(',')
      this.$set(this.form, name, values.join(','))
    },
    closeDatePicker (fieldName) {
      const date = new Date(this.form[fieldName + '_picker'])
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      let d = date.getDate()
      m = m < 10 ? "0" + m : m
      d = d < 10 ? "0" + d : d
      this.form[fieldName] = y + "-" + m + "-" + d
    },
    closeDateTimePicker (fieldName) {
      const date = new Date(this.form[fieldName + '_picker'])
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      let d = date.getDate()
      let h = date.getHours()
      let i = date.getMinutes()
      let s = date.getSeconds()
      m = m < 10 ? "0" + m : m
      d = d < 10 ? "0" + d : d
      h = h < 10 ? "0" + h : h
      s = s < 10 ? "0" + s : s
      this.form[fieldName] = y + "-" + m + "-" + d + " " + h + ":" + i + ":" + s
    },
    getProp (field) {
      if (field.type === this.FIELD_TYPE.DATE.v || field.type === this.FIELD_TYPE.DATETIME.v) {
        return field.name + '_picker'
      } else {
        return field.name
      }
    },
    getRules (field) {
      const rules = []
      if (field.must_fill === this.BOOL.YES.v) {
        rules.push({ required: true, message: this.getValidateText(field.type) + `${field.label}！` })
      }
      if (field.type === this.FIELD_TYPE.TEXT_IPT.v || field.type === this.FIELD_TYPE.MULTI_TEXT_IPT.v || field.type === this.FIELD_TYPE.MOBILE.v || field.type === this.FIELD_TYPE.EMAIL.v) {
        rules.push({ max: 255, message: '长度超出限制' })
      }
      return rules
    },
    getValidateText (fieldType) {
      let msg = '请选择'
      switch (fieldType) {
        case this.FIELD_TYPE.TEXT_IPT.v:
        case this.FIELD_TYPE.MULTI_TEXT_IPT.v:
        case this.FIELD_TYPE.NUM_IPT.v:
        case this.FIELD_TYPE.MOBILE.v:
        case this.FIELD_TYPE.EMAIL.v:
          msg = '请输入'
          break
        case this.FIELD_TYPE.FILE.v:
          msg = '请上传'
          break
      }
      return msg
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../assets/css/form.less";
</style>